<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改套餐' : '添加套餐'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
        <!-- <el-form-item label="代理商：" prop="operationId"  v-operation>
          <el-select
            v-model="modalData.operationId"
            placeholder="代理商名称"
          >
            <el-option
              v-for="(item, index) in agentList.list"
              :label="item.operationName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->

      <el-form-item label="套餐名称：" prop="goodsName">
        <el-input
          v-model="modalData.goodsName"
          placeholder="请输入套餐名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="套餐标题：" prop="goodsTitle">
        <el-input
          v-model="modalData.goodsTitle"
          placeholder="请输入套餐标题"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="套餐原价：" prop="goodsMarketPrice">
        <el-input
          v-model="modalData.goodsMarketPrice"
          placeholder="请输入套餐原价"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="套餐现价：" prop="goodsPrice">
        <el-input
          v-model="modalData.goodsPrice"
          placeholder="请输入套餐现价"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="套餐类别"
        prop="goodsTypeId"
      >
        <el-select v-model="modalData.goodsTypeId" placeholder="请选择套餐类别">
          <el-option v-for="(item, index) in categoryList" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="套餐类型：" prop="type">
        <el-radio-group v-model="modalData.type">
          <el-radio
            v-for="(item, index) in this.const.PRODUCT_TYPE"
            :label="item.value"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      

      <el-form-item
        label="使用次数："
        prop="canUseNum"
        v-if="modalData.type === this.const.PRODUCT_TYPE_CODE.ONCE"
      >
        <el-input
          v-model="modalData.canUseNum"
          placeholder="请输入套餐次数"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="套餐天数"
        prop="canUseDay"
        v-if="modalData.type === this.const.PRODUCT_TYPE_CODE.ONCE"
      >
        <el-input
          v-model="modalData.canUseDay"
          placeholder="请输入套餐天数"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="套餐天数" prop="useday" v-else>
        <el-input
          v-model="modalData.useday"
          placeholder="请输入套餐天数"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="产品描述：" prop="goodsDesc">
        <el-input
          type="type"
          autosize
          v-model="modalData.goodsDesc"
          placeholder="请输入产品描述"
          :maxLength="50"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { fetchProductCategory } from "@/api/common";

import { mapState } from "vuex";
export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  data() {
    // let operationId = this.$store.getters.userInfo.operationId;
    return {
      // operationId: operationId,
      isShowNum: false,
      formName: "form",
      ruleValidate: {
        goodsTypeId: [
          {
            required: true,
            message: "请选择套餐类别",
            trigger: "change",
          },
        ],
        // operationId: [
        //   {
        //     required: true,
        //     message: "请选择相关代理商",
        //     trigger: "change",
        //   },
        // ],
        goodsTypeId: [
          {
            required: true,
            message: "请选择套餐类别",
            trigger: "change",
          },
        ],
        goodsName: [
          {
            required: true,
            message: "请输入套餐名称",
            trigger: "change",
          },
        ],

        goodsTitle: [
          {
            required: true,
            message: "套餐标题不能为空",
            trigger: "change",
          },
        ],
        goodsMarketPrice: [
          {
            required: true,
            message: "套餐原价不能为空",
            trigger: "change",
          },
        ],
        goodsPrice: [
          {
            required: true,
            message: "套餐现价不能为空",
            trigger: "change",
          },
        ],
        useday: [
          {
            required: true,
            message: "套餐使用天数不能为空",
            trigger: "change",
          },
        ],
        canUseDay: [
          {
            required: true,
            message: "套餐使用天数不能为空",
            trigger: "change",
          },
        ],
        canUseNum: [
          {
            required: true,
            message: "套餐使用次数不能为空",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "套餐类型不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
     getProductCategory() {
      fetchProductCategory().then((res) => {
        let list = res.data.data || [];
        this.categoryList = list
        
      });
    },
    // 修改使用次数
    changeNum() {
      this.isShowNum = !!this.modalData;
    },

    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);

        if (obj.type === this.const.PRODUCT_TYPE_CODE.ONCE) {
          obj.useday = "";
        } else {
          obj.canUseDay = "";
          obj.canUseNum = "";
        }
        
        delete obj.updateAt;
        delete obj.createAt;
        obj.operationId = this.operationId !== this.const.ADMIN_OPERATION ? this.operationId : obj.operationId;
        obj.goodsPrice = obj.goodsPrice * 100;
        obj.goodsMarketPrice = obj.goodsMarketPrice * 100;
        this.$emit("submit", obj);
      });
    },
  },

  mounted() {
    this.getProductCategory();
    this.$store.dispatch("fetchAgentListAll");
  },
};
</script>
